import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { getDatabase, ref, set, get, update } from "firebase/database";
import firebaseApp, { KEY, KEY_SITE } from '../../integration/FirebaseConfig';
import { Helmet } from "react-helmet";
import * as MuiIcons from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const database = getDatabase(firebaseApp);

const Config = () => {
    const [textEditorContent, setTextEditorContent] = useState('');
    const [isGradient, setIsGradient] = useState(false);
    const [color1, setColor1] = useState('#ffffff');
    const [color2, setColor2] = useState('#ffffff');
    const [bannerFile, setBannerFile] = useState(null);
    const [bannerPath, setBannerPath] = useState('');
    const [logoSize, setLogoSize] = useState(0);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [logoPath, setLogoPath] = useState('');
    const [logoPreview, setLogoPreview] = useState(null);
    const [bannerLink, setBannerLink] = useState('');
    const [menuName, setMenuName] = useState('');
    const [menuLink, setMenuLink] = useState('');
    const [menuIcon, setMenuIcon] = useState('');
    const [menuActive, setMenuActive] = useState(false);

    const [links, setLinks] = useState({
        receitaOrcamentaria: "",
        receitaExtraorcamentaria: "",
        despesaOrcamentaria: "",
        despesaExtraorcamentaria: "",
        documentoPagamento: "",
        instrumentosPlanejamento: "",
        leisMunicipais: "",
        decretos: "",
        subvencaoSocial: "",
        emendasImpositivas: "",
        pessoal: "",
        licitacoes: "",
        publicacoes: "",
        contratosLicitatorios: "",
        contratos: "",
        conveniosFederal: "",
        recursosFederais: "",
        estagiarios: "",
        covid19: "",
        frotaMunicipal: "",
        rreo: "",
        rgf: "",
        loa: "",
        ldo: "",
        ppa: "",
        regulamentacaoLAI: "",
        regulamentacaoDiarias: "",
        renunciasFiscais: "",
        lgpd: "",
        tabelaDiarias: ""
    });

    const [orgaoNome, setOrgaoNome] = useState('');
    const [cnpj, setCNPJnome] = useState('');

    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');

    const [facebookLink, setFacebookLink] = useState('');
    const [instagramLink, setInstagramLink] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [iptuLink, setIptuLink] = useState('');
    const [notaFiscalLink, setNotaFiscalLink] = useState('');
    const [footer, setFooter] = useState('');
    const [resumoHistorico, setResumoHistorico] = useState('');

    const [menuName2, setMenuName2] = useState('');
    const [menuLink2, setMenuLink2] = useState('');
    const [menuIcon2, setMenuIcon2] = useState('');
    const [menuActive2, setMenuActive2] = useState(false);

    const [menuName3, setMenuName3] = useState('');
    const [menuLink3, setMenuLink3] = useState('');
    const [menuIcon3, setMenuIcon3] = useState('');
    const [menuActive3, setMenuActive3] = useState(false);

    const [manutencaoAtivo, setManutencaoAtivo] = useState(false);
    const [manutencaoMsg, setManutencaoMsg] = useState('');
    const [manutencaoCor, setManutencaoCor] = useState('#ffffff');
    const [manutencaoTempo, setManutencaoTempo] = useState('');
    const [manutencaoTimeAtivo, setManutencaoTimeAtivo] = useState(false);

    const primaryIcons = [
        'Home', 'Info', 'ContactMail', 'MenuBook', 'AccountCircle', 'Settings', 'Help', 'Description',
        'Dashboard', 'Event', 'Favorite', 'Flag', 'Flight', 'Forum', 'Gavel', 'Group', 'Headset', 'Highlight',
        'History', 'Language', 'Lightbulb', 'Lock', 'Mail', 'Map', 'Money', 'Notifications', 'Palette', 'People',
        'Person', 'Phone', 'Photo', 'Print', 'Public', 'Restaurant', 'Schedule', 'School', 'Search', 'Security',
        'ShoppingCart', 'Speaker', 'Star', 'ThumbUp', 'Traffic', 'Train', 'Verified', 'Visibility', 'Warning',
        'AccountBalance', 'AccountBalanceWallet', 'AttachMoney', 'CreditCard', 'MonetizationOn', 'MoneyOff',
    ];

    const handleLinkChange = (field, value) => {
        setLinks(prevLinks => ({
            ...prevLinks,
            [field]: value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(database, `db/cidades/${KEY}/config/`);
            const manutencaoRef = ref(database, `db/cidades/${KEY}/manutencao/`);

            const [configSnapshot, manutencaoSnapshot] = await Promise.all([
                get(dbRef),
                get(manutencaoRef)
            ]);

            if (configSnapshot.exists()) {
                const data = configSnapshot.val();
                if (data.background) {
                    const colors = data.background.match(/#[0-9a-fA-F]{6}/g);
                    if (colors) {
                        setColor1(colors[0]);
                        setColor2(colors[1] || colors[0]);
                        setIsGradient(colors.length > 1);
                    }
                }
                if (data.banner) setBannerPath(data.banner);
                if (data.logo) setLogoPath(data.logo);

                // Set data for Destaque sections
                if (data.destaque) {
                    setMenuName(data.destaque.name);
                    setMenuLink(data.destaque.link);
                    setMenuIcon(data.destaque.icon);
                    setMenuActive(data.destaque.active);
                }
                if (data.destaque2) {
                    setMenuName2(data.destaque2.name);
                    setMenuLink2(data.destaque2.link);
                    setMenuIcon2(data.destaque2.icon);
                    setMenuActive2(data.destaque2.active);
                }
                if (data.destaque3) {
                    setMenuName3(data.destaque3.name);
                    setMenuLink3(data.destaque3.link);
                    setMenuIcon3(data.destaque3.icon);
                    setMenuActive3(data.destaque3.active);
                }

                if (data.transparencia) {
                    setLinks({
                        receitaOrcamentaria: data.transparencia.receitaOrcamentaria || "",
                        receitaExtraorcamentaria: data.transparencia.receitaExtraorcamentaria || "",
                        despesaOrcamentaria: data.transparencia.despesaOrcamentaria || "",
                        despesaExtraorcamentaria: data.transparencia.despesaExtraorcamentaria || "",
                        documentoPagamento: data.transparencia.documentoPagamento || "",
                        instrumentosPlanejamento: data.transparencia.instrumentosPlanejamento || "",
                        leisMunicipais: data.transparencia.leisMunicipais || "",
                        decretos: data.transparencia.decretos || "",
                        subvencaoSocial: data.transparencia.subvencaoSocial || "",
                        emendasImpositivas: data.transparencia.emendasImpositivas || "",
                        pessoal: data.transparencia.pessoal || "",
                        licitacoes: data.transparencia.licitacoes || "",
                        publicacoes: data.transparencia.publicacoes || "",
                        contratosLicitatorios: data.transparencia.contratosLicitatorios || "",
                        contratos: data.transparencia.contratos || "",
                        conveniosFederal: data.transparencia.conveniosFederal || "",
                        recursosFederais: data.transparencia.recursosFederais || "",
                        estagiarios: data.transparencia.estagiarios || "",
                        covid19: data.transparencia.covid19 || "",
                        frotaMunicipal: data.transparencia.frotaMunicipal || "",
                        rreo: data.transparencia.rreo || "",
                        rgf: data.transparencia.rgf || "",
                        loa: data.transparencia.loa || "",
                        ldo: data.transparencia.ldo || "",
                        ppa: data.transparencia.ppa || "",
                        regulamentacaoLAI: data.transparencia.regulamentacaoLAI || "",
                        regulamentacaoDiarias: data.transparencia.regulamentacaoDiarias || "",
                        renunciasFiscais: data.transparencia.renunciasFiscais || "",
                        lgpd: data.transparencia.lgpd || "",
                        tabelaDiarias: data.transparencia.tabelaDiarias || ""
                    });
                }

                if (data.links.facebook) setFacebookLink(data.links.facebook);
                if (data.links.instagram) setInstagramLink(data.links.instagram);
                if (data.links.whatsapp) setWhatsappLink(data.links.whatsapp);
                if (data.links.youtube) setYoutubeLink(data.links.youtube);
                if (data.links.iptu) setIptuLink(data.links.iptu);
                if (data.links.notafiscal) setNotaFiscalLink(data.links.notafiscal);
                if (data.historia) setTextEditorContent(data.historia);
                if (data.cnpj) setCNPJnome(data.cnpj);
                if (data.orgaoNome) setOrgaoNome(data.orgaoNome);
                if (data.resumoHistorico) setResumoHistorico(data.resumoHistorico);
                if (data.email) setEmail(data.email);
                if (data.telefone) setTelefone(data.telefone);
                if (data.dev) setFooter(data.dev);
                if (data.logoSize) setLogoSize(data.logoSize);
            }

            if (manutencaoSnapshot.exists()) {
                const manutencaoData = manutencaoSnapshot.val();
                setManutencaoAtivo(manutencaoData.ativo || false);
                setManutencaoMsg(manutencaoData.msg || '');
                setManutencaoCor(manutencaoData.cor || '#ffffff');
                setManutencaoTempo(manutencaoData.tempo || '');
                setManutencaoTimeAtivo(manutencaoData.timeAtivo || false);
            }
        };

        fetchData();
    }, []);

    const uploadImage = async (file, endpoint) => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await fetch(`${KEY_SITE}${endpoint}`, {
            method: 'POST',
            body: formData,
        });
        return await response.text();
    };

    const handleSave = async () => {
        const gradientValue = isGradient
            ? `linear-gradient(to bottom, ${color1}, ${color2})`
            : color1;

        const bannerUploadPath = bannerFile ? await uploadImage(bannerFile, 'update_banner.php') : bannerPath;
        const logoUploadPath = logoFile ? await uploadImage(logoFile, 'upload_image.php') : logoPath;

        await set(ref(database, `db/cidades/${KEY}/config/`), {
            background: gradientValue,
            banner: bannerUploadPath,
            bannerLink,
            logo: logoUploadPath,
            logoSize: logoSize,
            orgaoNome: orgaoNome,
            resumoHistorico: resumoHistorico,
            cnpj: cnpj,
            telefone: telefone,
            email: email,
            dev: footer,
            destaque: { name: menuName, link: menuLink, icon: menuIcon, active: menuActive },
            destaque2: { name: menuName2, link: menuLink2, icon: menuIcon2, active: menuActive2 },
            destaque3: { name: menuName3, link: menuLink3, icon: menuIcon3, active: menuActive3 },
            historia: textEditorContent,
            links: {
                facebook: facebookLink,
                instagram: instagramLink,
                whatsapp: whatsappLink,
                youtube: youtubeLink,
                iptu: iptuLink,
                notafiscal: notaFiscalLink
            }
        });

        await update(ref(database, `db/cidades/${KEY}/config/transparencia`), links);

        await set(ref(database, `db/cidades/${KEY}/manutencao/`), {
            ativo: manutencaoAtivo,
            msg: manutencaoMsg,
            cor: manutencaoCor,
            tempo: manutencaoTempo,
            timeAtivo: manutencaoTimeAtivo
        });

        window.location.reload();
    };

    const handleFileChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Helmet>
                <title>Painel - Configurações</title>
            </Helmet>
            <Box m="20px">
                <Box mb="30px" p={3} borderRadius={3}>
                    <Grid container spacing={4}>
                        {/* Seção de Manutenção */}
                        <Grid item xs={12} md={6}>
                            <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                Configurações de Manutenção
                            </Divider>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={manutencaoAtivo}
                                        onChange={(e) => setManutencaoAtivo(e.target.checked)}
                                        sx={{
                                            color: 'gray',
                                            '&.Mui-checked': {
                                                color: 'green',
                                            },
                                        }}
                                    />
                                }
                                label="Modo de Manutenção Ativo"
                            />

                            <TextField
                                label="Mensagem de Manutenção"
                                value={manutencaoMsg}
                                onChange={(e) => setManutencaoMsg(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                type="color"
                                label="Cor de Fundo"
                                value={manutencaoCor}
                                onChange={(e) => setManutencaoCor(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Tempo de Inatividade (min)"
                                value={manutencaoTempo}
                                onChange={(e) => setManutencaoTempo(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <FormControlLabel
                                control={<Checkbox sx={{
                                    color: 'gray',
                                    '&.Mui-checked': {
                                        color: 'green',
                                    },
                                }} checked={manutencaoTimeAtivo} onChange={(e) => setManutencaoTimeAtivo(e.target.checked)} />}
                                label="Ativar Temporizador"
                            />
                        </Grid>

                        {/* Seção de Cores */}
                        <Grid item xs={12} md={6}>
                            <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                Configurações de Cores
                            </Divider>
                            <FormControlLabel
                                control={<Checkbox sx={{
                                    color: 'gray',
                                    '&.Mui-checked': {
                                        color: 'green',
                                    },
                                }} checked={isGradient} onChange={(e) => setIsGradient(e.target.checked)} />}
                                label="Usar Gradiente?"
                            />
                            <TextField
                                type="color"
                                label="Cor Primária"
                                value={color1}
                                onChange={(e) => setColor1(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            {isGradient && (
                                <TextField
                                    type="color"
                                    label="Cor Secundária"
                                    value={color2}
                                    onChange={(e) => setColor2(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setColor1('#096cff');
                                    setColor2('#3498DB');
                                    setIsGradient(true);
                                }}
                                sx={{ mt: 2 }}
                            >
                                Restaurar Cores
                            </Button>

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                Imagens do Site
                            </Divider>
                            <InputLabel htmlFor="banner-upload">Selecione um Banner</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                id="banner-upload"
                                onChange={(e) => handleFileChange(e, setBannerFile, setBannerPreview)}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                            {bannerPath && <img src={bannerPath} alt="Banner Atual" style={{ width: '100%', marginBottom: '10px' }} />}
                            {bannerPreview && <img src={bannerPreview} alt="Novo Banner" style={{ width: '100%', marginBottom: '10px' }} />}

                            <InputLabel htmlFor="logo-upload">Selecione uma Logo</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                id="logo-upload"
                                onChange={(e) => handleFileChange(e, setLogoFile, setLogoPreview)}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                            {logoPath && <img src={logoPath} alt="Logo Atual" style={{ width: '100px', marginBottom: '10px' }} />}
                            {logoPreview && <img src={logoPreview} alt="Nova Logo" style={{ width: '100px', marginBottom: '10px' }} />}
                            <TextField
                                label="Tamanho da Logo"
                                value={logoSize}
                                onChange={(e) => setLogoSize(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>

                        {/* Seção de Menu em Destaque */}
                        {[{ name: menuName, setName: setMenuName, link: menuLink, setLink: setMenuLink, icon: menuIcon, setIcon: setMenuIcon, active: menuActive, setActive: setMenuActive, label: "Menu em Destaque" },
                        { name: menuName2, setName: setMenuName2, link: menuLink2, setLink: setMenuLink2, icon: menuIcon2, setIcon: setMenuIcon2, active: menuActive2, setActive: setMenuActive2, label: "Menu em Destaque 2" },
                        { name: menuName3, setName: setMenuName3, link: menuLink3, setLink: setMenuLink3, icon: menuIcon3, setIcon: setMenuIcon3, active: menuActive3, setActive: setMenuActive3, label: "Menu em Destaque 3" }].map((destaque, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                    {destaque.label}
                                </Divider>
                                <TextField label={`Nome do ${destaque.label}`} value={destaque.name} onChange={(e) => destaque.setName(e.target.value)} fullWidth margin="normal" />
                                <TextField label={`Link do ${destaque.label}`} value={destaque.link} onChange={(e) => destaque.setLink(e.target.value)} fullWidth margin="normal" />
                                <InputLabel>Ícone do Destaque</InputLabel>
                                <Select
                                    value={destaque.icon}
                                    onChange={(e) => destaque.setIcon(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                >
                                    {primaryIcons.map((icon) => {
                                        const IconComponent = MuiIcons[icon];
                                        return (
                                            <MenuItem key={icon} value={icon}>
                                                <ListItemIcon><IconComponent /></ListItemIcon>
                                                <ListItemText primary={icon} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormControlLabel
                                    control={<Checkbox sx={{
                                        color: 'gray',
                                        '&.Mui-checked': {
                                            color: 'green',
                                        },
                                    }} checked={destaque.active} onChange={(e) => destaque.setActive(e.target.checked)} />}
                                    label="Ativo"
                                />
                            </Grid>
                        ))}



                        <Grid item xs={12} md={6}>
                            <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                CONFIG GERAIS
                            </Divider>
                            {/* Links */}
                            <TextField
                                label="Facebook"
                                value={facebookLink}
                                onChange={(e) => setFacebookLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Instagram"
                                value={instagramLink}
                                onChange={(e) => setInstagramLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Whatsapp"
                                value={whatsappLink}
                                onChange={(e) => setWhatsappLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Youtube"
                                value={youtubeLink}
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="IPTU"
                                value={iptuLink}
                                onChange={(e) => setIptuLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Nota Fiscal"
                                value={notaFiscalLink}
                                onChange={(e) => setNotaFiscalLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="CNPJ"
                                value={cnpj}
                                onChange={(e) => setCNPJnome(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                            />


                            <TextField
                                label="Footer Desenvolvedor"
                                value={footer}
                                onChange={(e) => setFooter(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Telefone"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Nome do Orgão"
                                value={orgaoNome}
                                onChange={(e) => setOrgaoNome(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Resumo Historico"
                                value={resumoHistorico}
                                onChange={(e) => setResumoHistorico(e.target.value)}
                                fullWidth
                                margin="normal"
                            />

                            <Divider
                                textAlign="center"
                                sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}
                            >
                                Historia
                            </Divider>
                            <ReactQuill
                                value={textEditorContent}
                                onChange={setTextEditorContent}
                                theme="snow"
                                placeholder="Digite seu conteúdo aqui..."
                                style={{ height: '300px', marginBottom: '20px' }}
                            />


                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Divider textAlign="center" sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                                LINKS DE TRANSPARÊNCIA
                            </Divider>

                            {Object.keys(links).map((key) => (
                                <TextField
                                    key={key}
                                    label={key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                    value={links[key]}
                                    onChange={(e) => handleLinkChange(key, e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            ))}

                        </Grid>

                        <Grid item xs={12}>
                            <Divider
                                textAlign="center"
                                sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px', mt: 2 }}
                            >
                                SALVAR
                            </Divider>

                            <Button variant="contained" sx={{ backgroundColor: 'blue' }} onClick={handleSave} fullWidth>
                                Salvar Configurações
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default Config;
