import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { getDatabase, ref, onValue, set } from "@firebase/database";
import firebaseApp, { KEY } from "../../integration/FirebaseConfig";

const database = getDatabase(firebaseApp);

const MesaDiretora = () => {
  const [vereadores, setVereadores] = useState([]);
  const [mesaDiretora, setMesaDiretora] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCargo, setSelectedCargo] = useState("");
  const [selectedVereador, setSelectedVereador] = useState("");

  const cargosMesaDiretora = [
    { id: "presidente", label: "Presidente" },
    { id: "vice_presidente", label: "Vice-Presidente" },
    { id: "primeiro_secretario", label: "Primeiro Secretário" },
    { id: "segundo_secretario", label: "Segundo Secretário" },
  ];

  useEffect(() => {
    // Carregar vereadores
    const vereadoresRef = ref(database, `db/cidades/${KEY}/vereadores`);
    onValue(vereadoresRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const vereadoresList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setVereadores(vereadoresList);
      }
    });

    // Carregar mesa diretora
    const mesaDiretoraRef = ref(database, `db/cidades/${KEY}/mesadiretora`);
    onValue(mesaDiretoraRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setMesaDiretora(data);
      }
    });
  }, []);

  const handleSelectCargo = (cargo) => {
    setSelectedCargo(cargo);
    setOpenDialog(true);
  };

  const handleSave = () => {
    if (selectedCargo && selectedVereador) {
      const vereador = vereadores.find((v) => v.id === selectedVereador);
      const mesaDiretoraRef = ref(
        database,
        `db/cidades/${KEY}/mesadiretora/${selectedCargo}`
      );
      set(mesaDiretoraRef, {
        id: vereador.id,
        nome: vereador.nome,
        partido: vereador.partido,
        cargo: cargosMesaDiretora.find((c) => c.id === selectedCargo)?.label,
        documentoUrl: vereador.documentoUrl,
      })
        .then(() => {
          setMesaDiretora((prev) => ({
            ...prev,
            [selectedCargo]: vereador,
          }));
          setOpenDialog(false);
          setSelectedCargo("");
          setSelectedVereador("");
        })
        .catch((error) => {
          console.error("Erro ao salvar no Firebase:", error);
        });
    }
  };

  return (
    <Box m="20px" color="#f5f5f5" minHeight="100vh" p={3}>
      <Typography variant="h4" gutterBottom>
        Mesa Diretora
      </Typography>
      <Box mt={3}>
        {cargosMesaDiretora.map((cargo) => (
          <Box
            key={cargo.id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            p={2}
            bgcolor="#333"
            borderRadius="8px"
            color="#f5f5f5"
          >
            <Typography variant="body1">
              {cargo.label}:{" "}
              {mesaDiretora[cargo.id]?.nome
                ? `${mesaDiretora[cargo.id].nome} (${mesaDiretora[cargo.id].partido})`
                : "Nenhum vereador selecionado"}
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
              }}
              onClick={() => handleSelectCargo(cargo.id)}
            >
              {mesaDiretora[cargo.id]?.nome ? "Editar" : "Selecionar"}
            </Button>
          </Box>
        ))}
      </Box>

      {/* Modal para selecionar vereador */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: "#2c2c2c",
            color: "#f5f5f5",
          },
        }}
      >
        <DialogTitle>Selecionar Vereador</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="vereador-select-label" style={{ color: "#f5f5f5" }}>
              Vereador
            </InputLabel>
            <Select
              labelId="vereador-select-label"
              value={selectedVereador}
              onChange={(e) => setSelectedVereador(e.target.value)}
              style={{ color: "#f5f5f5", backgroundColor: "#424242" }}
            >
              {vereadores.map((vereador) => (
                <MenuItem key={vereador.id} value={vereador.id}>
                  {vereador.nome} - {vereador.partido}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            style={{ color: "#ff5252" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MesaDiretora;
