import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Snackbar, Alert, Select, MenuItem } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import { Helmet } from 'react-helmet';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { generateToken } from '../../services/script';


function cpfIsValid(cpf) {
  cpf = String(cpf).replace(/\D/g, '');
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  return remainder === parseInt(cpf.charAt(10));
}

const FirstScreen = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState('');
  const [cidades, setCidades] = useState([]);
  const [cpf, setCpf] = useState('');
  const [cpfError, setCpfError] = useState('');
  const [nome, setNome] = useState('');



  /*   const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    }; */

  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    const database = getDatabase();
    const cidadesRef = ref(database, 'codigo_cidade');


    onValue(cidadesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const citiesArray = Object.entries(data)
          .map(([key, value]) => ({
            id: key,
            codigo: value.codigo,
            nome: value.nome,
            ativo: value.ativo,
            bloqueado: value.bloqueado
          }))
          .filter(city => city.ativo === true);
        setCidades(citiesArray);
      }


    });
  }, []);



  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);


    const validations = {
      minLength: newPassword.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    };

    setPasswordValidations(validations);
  };

  const isPasswordValid = Object.values(passwordValidations).every((validation) => validation);

  const handleCpfChange = (value) => {

    const formattedCpf = value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    setCpf(formattedCpf);
  };

  const verificaCamposPreenchidos = () => {
    return nome && email && password && selectedCity;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      if (verificaCamposPreenchidos()) {

        const emailTratado = email.toLocaleLowerCase();
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const ativo = true;
        let KEY_NAME = "";
        const cidade = selectedCity;
        const cidadeSelecionada = cidades.find(cidade => cidade.codigo === selectedCity);
        const cidadeNome = cidadeSelecionada ? cidadeSelecionada.nome : "";
        const now = new Date();
        const horaLogin = now.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        const licensa = generateToken(15)
        KEY_NAME = cidadeNome
        const site = ''
        const categoria = 'Operador'
        const acesso = false
        const master = false
        const dualAcess = true
        const db = getDatabase();

        await set(ref(db, `usuarios/${user.uid}`), { email: emailTratado, site, licensa, master, categoria, ativo, horaLogin, cidade, KEY_NAME, nome, acesso, dualAcess });
        navigate("/");

      }


    } catch (error) {
      setError('E-mail digitado ja está em uso.');
      console.error('Erro ao criar usuário:', error.message);
    }

  };

  return (
    <>
      <Helmet>
        <title>Painel DHF - Cadastro</title>
      </Helmet>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>

      <div className="login-container" >
        <div className="logo-container">
          <img src="/images/logobs.png" alt="Logo" width="250" />
        </div>
        <div className="login-form">
          <h1>CADASTRO</h1>
          <form onSubmit={handleSignUp}>
            <div className="input-group">
              <label>Seu E-mail: </label>
              <label style={{ color: "red" }} > * </label>
              <input type="text" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>

            <div className="input-group">
              <label>Nome: </label>
              <label style={{ color: "red" }} > * </label>
              <input type="text" placeholder="Digite seu nome" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>

            <div className="input-group">
              <label>Sua Senha:</label>
              <label style={{ color: "red" }} > * </label>
              <div className="password-input">
                <input
                  type={'password'}

                  placeholder="Digite sua Senha"
                  value={password}
                  onChange={handlePasswordChange}
                />

              </div>
              <p style={{ fontSize: 14, color: '#6c757d', fontWeight: 'bold' }}>
                Sua senha deve conter:
              </p>
              <div className="password-validations" style={{ fontSize: 12 }}>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.minLength ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Mínimo de 8 caracteres</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.uppercase ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos uma letra maiúscula</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.number ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos um número</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.specialChar ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos um caractere especial</p>
              </div>
            </div>

            <div className="input-group"  >
              <label>Selecione seu Acesso:</label>
              <label style={{ color: "red" }} > * </label>


              <Select
                className="city-select"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                style={{ width: 200 }}

              >
                <MenuItem value="" disabled>
                  Acesso
                </MenuItem>
                {cidades.map((cidade) => (
                  <MenuItem key={cidade.codigo} value={cidade.codigo}>
                    {cidade.nome} - {cidade.codigo}
                  </MenuItem>
                ))}
              </Select>


              {/*           <button className="login-button" onClick={toggleShowPassword} type="submit">
              Show
            </button>
 */}
            </div>
            <button className="login-button" disabled={!isPasswordValid} type="submit">
              Cadastrar
            </button>

            <p style={{ textAlign: 'center' }}>
              Já possui uma conta? <Link style={{ color: '#0F71FD', textDecoration: 'none' }} to="/login">Login</Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default FirstScreen;
