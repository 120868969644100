import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import firebaseApp, { DATA_LOGIN, KEY, KEY_CATEGORIA, KEY_LICENSE, KEY_MASTER, KEY_NOME, NAME } from "../integration/FirebaseConfig";
import {
    Box, Typography, IconButton, useTheme, Avatar, Skeleton, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Button, Select, InputLabel, FormControl, ListItem, RadioGroup,
    FormControlLabel,
    Radio, List, ListItemText, ListItemAvatar, ListItemIcon, Collapse,
    ListItemButton
} from "@mui/material";
import '../index.css'
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Switch from '@mui/material/Switch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getDatabase, off, onValue, ref, update } from "firebase/database";
import KeyIcon from '@mui/icons-material/Key';
import { getAuth } from 'firebase/auth';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import GavelIcon from '@mui/icons-material/Gavel';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import StorefrontIcon from '@mui/icons-material/Storefront';

const Item = ({ title, to, icon, selected, setSelected, collapseSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            icon={icon}
            onClick={() => {
                setSelected(title);
                collapseSidebar(true); // Renomeado
            }}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const MenuRouter = ({ selecteds, isCollapseds, collapseSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(isCollapseds);
    const [selected, setSelected] = useState(selecteds);
    const keyCategoriaString = KEY.toString();

    return (
        <>
            <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 15px">
                <Typography>Principal</Typography>
            </Typography>


            {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (
                <>

                    <Item
                        title="Licitações"
                        to={`/${KEY}/licitacoes`}
                        icon={<GavelIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Frota"
                        to={`/${KEY}/veiculos`}
                        icon={<DirectionsCarIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Noticias"
                        to={`/${KEY}/noticias`}
                        icon={<NewspaperIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Documentos"
                        to={`/${KEY}/documentos`}
                        icon={<AssignmentIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="E-SIC"
                        to={`/${KEY}/e-sic`}
                        icon={<AssignmentIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Sala Empreendedor"
                        to={`/${KEY}/salaempreendedor`}
                        icon={<StorefrontIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Usuarios"
                        to={`/${KEY}/usuarios`}
                        icon={<Diversity3Icon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />



                    {keyCategoriaString.length == 8 && (
                        <>

                            <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                                <Typography>Outros</Typography>
                            </Typography>

                            <Item
                                title="Vereadores"
                                to={`/${KEY}/vereadores`}
                                icon={<RecordVoiceOverIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                            <Item
                                title="Legislativo"
                                to={`/${KEY}/legislativo`}
                                icon={<WysiwygIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                            <Item
                                title="Mesa Diretora"
                                to={`/${KEY}/mesadiretora`}
                                icon={<WysiwygIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                        </>
                    )}

                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/configurar`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_LICITA_USER && (
                <>

                    <Item
                        title="Licitações"
                        to={`/${KEY}/licitacoes`}
                        icon={<GavelIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />


                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_RH_USER && (
                <>


                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Documentos"
                        to={`/${KEY}/documentos`}
                        icon={<AssignmentIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER && (
                <>

                    <Item
                        title="Licitações"
                        to={`/${KEY}/licitacoes`}
                        icon={<GavelIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Frota"
                        to={`/${KEY}/veiculos`}
                        icon={<DirectionsCarIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />


                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Noticias"
                        to={`/${KEY}/noticias`}
                        icon={<NewspaperIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Documentos"
                        to={`/${KEY}/documentos`}
                        icon={<AssignmentIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Sala Empreendedor"
                        to={`/${KEY}/salaempreendedor`}
                        icon={<StorefrontIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Usuarios"
                        to={`/${KEY}/usuarios`}
                        icon={<Diversity3Icon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    {/*                   <Item
                    title="Dados"
                    to={`/${KEY}/dados`}
                    icon={<InsertChartIcon />}
                    selected={selected}
                    setSelected={setSelected}
                      collapseSidebar={collapseSidebar}
                  />
 */}




                    {keyCategoriaString.length == 8 && (
                        <>

                            <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                                <Typography>Outros</Typography>
                            </Typography>

                            <Item
                                title="Vereadores"
                                to={`/${KEY}/vereadores`}
                                icon={<RecordVoiceOverIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                            <Item
                                title="Legislativo"
                                to={`/${KEY}/legislativo`}
                                icon={<WysiwygIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                        </>
                    )}

                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_USER && (
                <>


                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Documentos"
                        to={`/${KEY}/documentos`}
                        icon={<AssignmentIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />



                    {keyCategoriaString.length == 8 && (
                        <>

                            <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                                <Typography>Outros</Typography>
                            </Typography>

                            <Item
                                title="Vereadores"
                                to={`/${KEY}/vereadores`}
                                icon={<RecordVoiceOverIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                            <Item
                                title="Legislativo"
                                to={`/${KEY}/legislativo`}
                                icon={<WysiwygIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                        </>
                    )}

                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_MIDIA_USER && (
                <>


                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                    <Item
                        title="Noticias"
                        to={`/${KEY}/noticias`}
                        icon={<NewspaperIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />


                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_SALA_EMPREENDEDOR_USER && (
                <>

                    <Item
                        title="Noticias"
                        to={`/${KEY}/noticias`}
                        icon={<NewspaperIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />


                    <Item
                        title="Avisos Sociais"
                        to={`/${KEY}/avisos`}
                        icon={<AnnouncementIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Sala Empreendedor"
                        to={`/${KEY}/salaempreendedor`}
                        icon={<StorefrontIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                        <Typography>Ajustes</Typography>
                    </Typography>

                    <Item
                        title="Configurações"
                        to={`/${KEY}/config`}
                        icon={<SettingsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />

                    <Item
                        title="Deslogar"
                        to={`/${KEY}/logout`}
                        icon={<ExitToAppIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        collapseSidebar={collapseSidebar}
                    />
                </>


            )}

        </>
    );
};

export default MenuRouter;
